<template>
  <div class="page__news-add">
    <y-form
      style="width: 100%"
      label-width="100px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetText: '取消'
      }"
      @submit="submit"
      @reset="reset"
    ></y-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import moment from 'moment'
import Upload from '@/components/upload'
import Editor from '@/components/editor'

export default {
  name: 'BannerAdd',
  data() {
    return {
      model: {
        title: '',
        createTime: null,
        jump: undefined,
        content: '',
        file: null
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '标题',
            prop: 'title'
          }
        },
        // {
        //   type: 'date',
        //   formItemProps: {
        //     style: 'width: 460px',
        //     label: '时间',
        //     prop: 'createTime'
        //   }
        // },
        // {
        //   type: 'radio-group',
        //   formItemProps: {
        //     style: 'width: 460px',
        //     label: '是否跳转',
        //     prop: 'jump'
        //   },
        //   attributes: {
        //     options: [
        //       {
        //         label: true,
        //         render() {
        //           return '是'
        //         }
        //       },
        //       {
        //         label: false,
        //         render() {
        //           return '否'
        //         }
        //       }
        //     ]
        //   }
        // },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '图片',
            prop: 'file'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        },
        {
          formItemProps: {
            label: '内容',
            prop: 'content'
          },
          render(h, prop, field, model) {
            return <Editor v-model={model[prop]}></Editor>
          }
        }
      ],
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      }
    }
  },
  computed: {
    newsId() {
      return this.$route.params.id
    }
  },
  watch: {
    newsId: {
      handler(val) {
        if (val) this.fetchInfo()
      },
      immediate: true
    }
  },
  methods: {
    async fetchInfo() {
      const res = await this.$serve.mpArticleInfo({
        aimid: this.newsId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.title = res.title
      this.model.file = {
        uid: uid(),
        status: 'success',
        url: res.showImg
      }
      this.model.content = res.content
      if (res.contextUrl) this.fetchContent(res)
    },
    async fetchContent(record) {
      const res = await this.$serve.oss({
        urlQuery: record.contextUrl
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.content = res
    },
    async submit({ file, createTime, ...data }) {
      {
        /* if (createTime) {
        data.createTime = moment(createTime).format('YYYY-MM-DD')
      } */
      }
      if (file?.response?.data || file?.url) {
        data.showImg = file.response?.data || file.url
      }
      if (this.newsId) {
        data.id = this.newsId
        this.update(data)
      } else {
        this.add(data)
      }
    },
    async add(data) {
      const res = await this.$serve.mpArticleAdd({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('文章添加成功')
      this.$router.push('/mini-article')
    },
    async update(data) {
      const res = await this.$serve.mpArticleUpdate({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('文章修改成功')
      this.$router.push('/mini-article')
    },
    reset() {
      this.$router.push('/mini-article')
    }
  }
}
</script>

<style lang="scss" scoped>
.page__news-add {
  ::v-deep .upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
</style>
